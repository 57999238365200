import { createI18n } from "vue-i18n";
import { languages, defaultLocale } from './lang'


const messages = Object.assign(languages)
export const i18n = createI18n({
    locale: defaultLocale,
    messages
});

export const $t = i18n.global.t
