class Token {
    getToken() {

        return localStorage.getItem('token');
    }
    decodeToken() {
        const token = this.getToken();
        if (token) {
            const base64Url = token.split('.')[1];
            const base64 = base64Url.replace('-', '+').replace('_', '/');
            return JSON.parse(window.atob(base64));
        }
        return null;
    }
    setToken(token) {
        localStorage.setItem('token', token);
    }
    removeToken() {
        localStorage.removeItem('token');
    }
    hasToken() {
        return localStorage.getItem('token') !== null;
    }
}
export default new Token();