import en from './locales/en.json'
import ro from './locales/ro.json'


// english in dev and romanian in prod
export const defaultLocale = process.env.NODE_ENV === 'production' ? 'en' : 'en'

export const languages = {
    en,
    ro,

}