<template>
  <v-snackbar v-bind="$attrs" location="top">
    {{ message }}
  </v-snackbar>
</template>

<script>
export default {
  props: {
    message: {
      type: String,
      default: "",
    },
  },
};
</script>

<style>
</style>